import React from "react"
import Layout from "../components/layout-2"
import { Amplify } from "aws-amplify"
import awsconfic from "../aws-exports"
import { withAuthenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"
import Profile from "../components/profile"

Amplify.configure(awsconfic)

const ProfilePage = ({ signOut }) => {
  return (
    <Layout path="/using-ssr">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Your connections</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {<Profile />}
        </div>
      </main>
    </Layout>
  )
}

export default withAuthenticator(ProfilePage)
